import React from "react";

const TrustpilotWidget = ({ className, theme, template = "mini" }: { className?: string, theme?: 'light' | 'dark', template?: "mini" | "micro" }) => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [theme, template]);
  return null;
  if (template === "mini") {
    return (
      <div ref={ref} className={`trustpilot-widget dui-bg-transparent ${className}`} data-locale="en-GB" data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="5a2fa6d80a1d740ea8222914" data-style-height="150px" data-style-width="100%" data-theme={theme}>
        <a href="https://uk.trustpilot.com/review/theyachtweek.com" target="_blank" rel="noopener">Trustpilot</a>
      </div>
    )
  }
  return (
    <div ref={ref} className={`trustpilot-widget ${className}`} data-locale="en-GB" data-template-id="5419b637fa0340045cd0c936" data-businessunit-id="5a2fa6d80a1d740ea8222914" data-style-height="20px" data-style-width="100%" data-theme={theme}>
      <a href="https://uk.trustpilot.com/review/theyachtweek.com" target="_blank" rel="noopener">Trustpilot</a>
    </div>
  );
}

export default TrustpilotWidget;